import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const VerwarmingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/verwarming/hero.jpg" }) {
        ...heroImage
      }
      buderus: file(
        relativePath: { eq: "images/verwarming/services/buderus.jpg" }
      ) {
        ...columnImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Verwarming - Uytterhaegen Jan BV" pathname="/verwarming" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Verwarming</h2>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h1>Verwarming</h1>
                  <p>
                    Door de strenge milieunormen en hoge energieprijzen, is het
                    logisch dat u uw stooklokaal vernieuwt om te voldoen aan de
                    wettelijke normen en om te besparen. Doorgaans worden oude
                    stooktoestellen vervangen door condensatie ketels. Vanaf 19
                    september 2015 mogen er trouwens nog enkel condensatie
                    ketels geplaatst worden met enkele uitzonderingen.
                  </p>
                  <p>
                    Bij ons kan je terecht voor zowel gas, stookolie-installatie
                    als warmtepompen. Omdat jarenlange ervaring gecombineerd met
                    kwalitatief materiaal resulteert in een professioneel
                    resultaat, werken wij voornamelijk samen met het merk
                    Buderus.
                  </p>
                  <p>
                    Ook voor vernieuwen van verwarmingselementen en plaatsen van
                    regelingen om uw installatie te optimaliseren kan je bij ons
                    terecht.
                  </p>
                </div>
                <div className="column-40">
                  <Img
                    alt={data.buderus.childImageSharp.name}
                    fluid={data.buderus.childImageSharp.fluid}
                  />
                </div>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VerwarmingPage
